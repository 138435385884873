@import "~bootstrap/scss/bootstrap";
@import "mixins";
@import "common";

// page and unique componant styles

//page usres
.wrap-inactive {
  display: flex;
  .icon {
    margin-left: 6px;
    margin-top: 3px;
    cursor: pointer;
  }
}
.footer-table {
  font-size: 12px;
  .form-select {
    font-size: 12px;
  }
  .pagination {
    .btn-link {
      color: $secondary-dark;
    }
  }
}

//View Modal Styles
.view-modal {
  .modal-body {
    .list-group {
      border: 0;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;

      .list-group-item {
        border: 0;
        width: 50%;
        padding-left: 0;

        label {
          color: #707070;
          font-size: 12px;
          font-weight: 500;
        }

        span {
          display: block;
          color: #202833;
          font-size: 14px;
        }
      }
    }
  }
  &.view-audit-modal {
    .subtitle-1.logdetail {
      position: relative;
    }
    hr {
      position: absolute;
      top: -5px;
      width: 81%;
      right: 0;
    }
  }
}
// page QR genarate
.qr-genarate-page {
  .card-set-qr {
    .card {
      margin-bottom: 16px;
      .wrap-img {
        width: 100%;
        height: 196px;
        overflow: hidden;
        margin: auto;
        text-align: center;
        background: lightgray;
        .card-img-top {
          padding: 20px;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 200px;
        }
        @include respond-max(lg) {
          height: 260px;
        }
        @include respond-max(lg) {
          height: 196px;
        }
        @include respond-min(ms) {
          height: 140px;
        }
        @include respond-min(md) {
          height: 180px;
        }
        @include respond-min(sm) {
          height: 150px;
        }
      }
      .btn.btn-cancel {
        position: relative;
        background-color: #d5dde570;
        border: none;
        .lcon-lang {
          position: absolute;
          left: 0;
          margin: 4px 0 0 8px;
        }
        .txt-lan {
          margin-left: 20px;
        }
        svg {
          path {
            stroke: #707070;
          }
        }
      }
      .btn-cancel.btn {
        padding: 4px 10px !important;
        outline: none;
        height: 30px;
      }
      @include respond-min(md) {
        margin-bottom: 20px;
      }
      .card-body {
        p {
          margin-bottom: 0;
          font-size: 13px;
          margin: auto 5px;
        }
      }
      &:hover {
        cursor: pointer;
        background-color: rgb(0 0 0 / 2%);
      }
    }
  }
  .temp-card-dropdown {
    .dropdown-toggle::after {
      display: none;
    }
    .badge {
      margin-left: 10px;
      font-weight: 500;
    }
    .dropdown-menu {
      border: 1px solid #dbdbdb;
      border-radius: 0.25rem;
      box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    }
  }
  .deactivated {
    pointer-events: none;
    opacity: 0.6;
  }
}
.react-joyride__tooltip {
  div {
    &:last-of-type {
      margin-top: 0 !important;
    }
    &:first-of-type {
      div {
        padding: 40px 10px 20px !important;
      }
    }
  }
  button[data-action="primary"] {
    font-family: $font-family-base;
    font-size: 14px !important;
    outline: none;
    padding: 8px 20px !important;
    -webkit-tap-highlight-color: transparent;
  }
}
.modal-dialog {
  &.modal-template {
    @media (min-width: 992px) {
      max-width: 680px;
    }
  }
  &.view-template-modal {
    @media (min-width: 992px) {
      max-width: 600px;
    }
    .modal-body {
      .list-group {
        border: 0;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;

        .list-group-item {
          border: 0;
          width: 50%;

          label {
            color: #707070;
            font-size: 12px;
            font-weight: 500;
          }

          span {
            display: block;
            color: #202833;
            font-size: 14px;
          }
        }
      }
    }
    .credit-party-details {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .view-template-stats {
        width: 250px;
        .list-group-item {
          width: 100% !important;
          white-space: nowrap;
        }
      }
      @media (max-width: 992px) {
        flex-direction: column;
        .view-template-stats {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
}
.template-page {
  margin-top: -45px;
  .title {
    .btn-link {
      margin-left: -3px;
    }
  }
  .form-2 {
    padding-right: 60px;
    .csv-download {
      .wrap-csv-temp {
        display: inline-block;
        margin: auto 20px;
      }
    }
    .desired-output {
      .form-check {
        display: block;
        @include respond-max(xxl) {
          display: inline-block;
        }
      }
    }
  }
  .left {
    padding: 45px 34px;
  }
  .right {
    padding: 45px 35px 35px 35px;
    background-color: $white;
    box-shadow: $box-shadow1;
    .preview {
      border: 0px !important;
      border-color: transparent;
      text-align: right;

      .card-body {
        padding: 20px 0;
      }
    }
  }
  .add-padding {
    padding-bottom: 80px;
  }
  .footer-card {
    position: fixed;
    margin-left: -10px;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    padding: 20px 30px 0 30px;
    box-shadow: rgb(0 0 0 / 15%) -2px -3px 14px -1px;
    text-align: right;
    margin-right: -30px;
    padding-bottom: 20px;
    .frm-btn {
      margin-right: 94px;
    }
  }
}
.page-audit {
  .btn-csv {
    text-align: right;
    .btn {
      padding: 0px 12px;
    }
    .ic-export {
      svg {
        font-size: 20px;
      }
    }
    @include respond-min(smm) {
      margin-top: 20px;
    }
  }
  table {
    tbody {
      tr {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

#error-body-csv {
  color: $danger !important;
  background-color: rgb(220 20 60 / 10%);
  .message-error {
    padding-bottom: 10px;
  }
}

.word-break {
  word-break: break-all;
}

.back-btn {
  margin-left: -4px;
}
.fill-parameter {
  .table.tbl-fill-para {
    border: 1px solid #d5dde5;
    tr {
      th {
        color: #383636;
        font-size: 12px;
        font-weight: bold;
      }
    }
    tr {
      td {
        font-size: 12px;
      }
    }
  }
  .left-p {
    padding-left: 10px;
  }
}

.user-page {
  .table-responsive {
    min-height: 300px;
  }
}
// .table-drpdwn-fix {
//   overflow-x: hidden;
// }
.mfa-setup-page {
  .mfa-setup-instructions {
    // margin: 0 400px 0 0;
    padding: 50px;
    color: #707070;
    font-size: 12px;
    font-weight: 500;
    width: 60%;
    @include respond-min(ms) {
      width: 80%;
      padding: 10px;
    }
    @include respond-min(sm) {
      width: 100%;
      padding: 20px;
    }
  }
}
.totp-page {
  .mfa-setup-instructions {
    color: #707070;
    font-size: 12px;
    font-weight: 500;
  }
}

.canvas {
  position: absolute !important;
  top: 0;
  left: 0;
}
#left-pane-canvas {
  width: 300px;
  height: 100%;
}

#pdf-canvas {
  border: rgba(220, 20, 60, 10%);
  margin-left: 300px;
  padding-bottom: 50px;
}

#draw-canvas {
  border: 1px solid rgba(34, 33, 33, 0.1);
  margin-bottom: 100px;
}

#canvas-page {
  margin-top: -45px;
  #canvas-container {
    margin-top: 110px;
    position: absolute;
    // position: relative !important;
    // margin: 40px 0;
    left: 120px;
  }
  .card {
    border: none;
    border-radius: 0;
    min-width: 100%;
    position: fixed;
    z-index: 999;
    left: 70px;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    .wrap-content {
      padding: 0 10px;
      @include respond-min(lg) {
        margin-left: 0;
      }
    }
    .v-border {
      border-left: 1px solid rgba(34, 33, 33, 0.1);
    }
    svg {
      font-size: 20px;
      margin-right: 5px;
    }
    .hide {
      @include respond-min(xl) {
        display: none;
      }
    }
  }
  .toolbar-overlay {
    height: 88px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: -71px;
    z-index: 1200;
    mix-blend-mode: hard-light;
  }

  .wrap-content {
    margin-left: 70px;
    .wrap-group {
      text-align: left;
      // border: 1px solid rgba(99, 97, 97, 0.1);
      margin: 0 20px;
      padding: 5px;
      // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      .group {
        // border: 1px solid #ced4da;
        border-radius: 4px;
        // padding: 10px;
        display: flex;
        padding-top: 10px;
        margin-bottom: 10px;
        // margin-left: 30px;
        // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        .verticle {
          margin-left: 10px;
        }
      }
    }
  }
  .bold {
    font-weight: 500;
  }
  .group2 {
    margin-left: 30px;
  }
  .form-label {
    // min-width: 50px;
    align-self: center;
    font-size: 11px;
    margin-right: 10px;
    // margin-left: 20px;
    margin-bottom: 0;
  }
  .fonts.form-control {
    width: 60px;
    font-size: 12px;
  }

  .color-lbl.form-label {
    margin-left: 20px;
  }
  .colors.form-control.form-control-color {
    font-size: 12px;
  }
  .m-btn {
    margin: auto 0;
  }
  .btn {
    // height: auto;
    font-size: 11px;
    @media (max-width: 1399.98px) {
      padding: 8px 15px;
    }
  }
  .text-alignment-size{
    width: 100px;
    font-size: 12px;

  }
  .pdf-import{
    margin-left: -70px !important;
  }
}
.canvas-content {
  .btn {
    padding: 8px 20px;
  }
  .btn-align {
    svg {
      height: 20px;
    }
  }
  [type=radio] {
    position: absolute;
    opacity: 0;
  }
  [type=radio]+svg {
    cursor: pointer;
    margin-right: 0.5rem;
  }
  [type=radio]:checked + svg {
    outline: 5px solid #adb5bd;
  }
  .space-add {
    padding-left: 20px;
  }
}
.desired-output {
  display: block;
  @include respond-between(md, lg) {
    display: block;
  }
}

//session exp page
.page-session {
  background-color: #909090;
  display: flex;
  justify-content: center;
  height: 100vh;
  .card.session {
    font-family: "Arial";
    color: #3b3b3b;
    border-radius: 0;
    margin-top: 5%;
    width: 320px;
    padding: 15px;
    height: 262px;
    // box-shadow: rgba(0, 0, 0, 0.5) 0px 0.0625em 0.0625em,
    //   rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    //   rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
    .session-logo {
      img {
        width: 140px;
        display: block;
        margin: auto;
        padding: 20px 0px;
      }
    }
    #btn-yes {
      font-size: 14px;
      font-weight: bold;
      // margin: 20px 0px 10px 0px;
      height: 40px;
      width: 100%;
      color: #fff;
      background-color: #202833;
    }
  }
}

#react-joyride-step-1 {
  .__floater__open {
    z-index: 1300 !important;
  }
}

#full-screen-modal {
  z-index: 1500;
  padding-left: 70px;
  .preview-canvas-container {
    position: relative;
    display: flex;
    #preview-canvas {
      margin: 40px auto;
    }
  }
}
.preview-temp {
  width: 50%;
  margin: auto;
  justify-content: center;
  .update-btn {
    margin-top: 27px;
    width: 100%;
  }
}
#full-screen-modal {
  width: 101%;
}

#generate-button {
  margin: 5px;
}
.wrap-create-role-form {
  .role-name {
    width: 70%;
    @media (max-width: 991.98px) {
      width: 100%;
    }
  }
  .role-desc {
    width: 100%;
    @media (max-width: 991.98px) {
      width: 100%;
    }
  }
  #create-role-button {
    margin-top: 30px;
  }
}

// .qr-spec {
//   width: 1600px;
//   overflow-x: scroll;
// }
.set-min-width {
  min-width: 250px;
}
.pipeline-icon {
  display: flex;
  text-align: center;
  justify-content: flex-start;
  .dash {
    margin: auto -8px;
  }
  .correct {
    margin: 5px;
    width: 20px;
    height: 20px;
    background: white;
    border: 2px solid #5cb85c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    svg {
      fill: #5cb85c;
      margin: auto;
    }
  }
  .fill-arrow {
    margin: 5px;
    width: 20px;
    height: 20px;
    background: white;
    border: 2px solid #707070;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    svg {
      fill: #707070;
      margin: auto;
    }    
    &:first-child {
      margin-left: 0;
    }
    &:first-child {
      margin-left: 0;
    }
  }
  .arrow {
    margin: 5px;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid #b6b6b6;
    display: flex;
    justify-content: center;
    svg {
      fill: #b6b6b6;
      margin: auto;
    }
  }
  .reject {
    margin: 5px;
    width: 20px;
    height: 20px;
    background: white;
    border: 2px solid #e61610;
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }
  svg {
    fill: #e61610;
    margin: auto;
  }
  .dash-green {
    svg {
      fill: #5cb85c;
    }
  }
  .dash-black {
    svg {
      fill: #707070;
    }
  }
  .dash-dark {
    svg {
      fill: #b6b6b6;
    }
  }
}

.indent,
.indent-error {
  margin-left: 30px !important;
  width: 90%;
  float: right;
}

.st-published {
  color: #5cb85c;
}
.st-testing {
  color: #e9ae07;
}
.st-review {
  color: #0a10c3;
}
.st-draft {
  color: #707070;
}
.st-reject {
  color: #df0b28;
}

.status-qr-sp {
  //text-align: center;
  font-weight: bold !important;
  .published {
    color: #6fb973;
  }
  .testing {
    color: #e3b748;
  }
  .in-review {
    color: #4b4dcb;
  }
  .draft {
    color: #807f80;
  }
  .rejected {
    color: #d24650;
  }
}

.btn-active-view {
  // display: inline-table;
  margin: 10px;
  padding: 0 !important;
}

.arrow-col,
.option-col {
  width: 0%;
  .dropdown {
    margin-top: 0px;
    .btn {
      padding: 0px;
    }
  }
  .arrow-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 20px;
    margin-top: -8px;
    .btn {
      background-color: transparent;
      color: #707070;
      padding: 0px;
      height: 20px;
      &:hover {
        background-color: transparent;
      }
      &:focus {
        border: none;
        box-shadow: none;
        background-color: transparent;
      }
    }
    .arrow {
      width: 70%;
    }
  }
}
#map-market-fields-form {
  .table > :not(caption) > * > * {
    border-bottom: 1px solid #d5dde5;
  }
  .table-responsive {
    overflow-x: unset;
  }
}

.nw-specification.qr-spec-list-page {
  padding-top: 45px;
  .table tr th {
    &:nth-child(1) {
      text-align: left !important ;
    }
    &:nth-child(2) {
      text-align: left !important;
    }
    text-align: center;
  }
}

.qr-spec-list-page {
  .back-link {
    cursor: pointer;
    margin-bottom: 10px;
  }
}

.nw-specification {
  margin-top: -45px;
  .left {
    padding: 45px 34px;
  }
  .page-title-row {
    margin-bottom: 0px;
    .breadcrumb {
      color: #202833;
      font-size: 1.1rem;
      margin-bottom: 1.438rem;
    }
  }
  form {
    @media screen and (max-width: 425px) {
      padding-right: 0px !important;
    }
    .title {
      padding-bottom: 30px;
      .sub-title1 {
        font-size: 12px;
        color: $grey;
      }
      .sub-title2 {
        font-weight: 400;
        font-size: 1.75rem;
      }
    }
    .form-control,
    .form-select {
      padding: 0.5rem 1rem;
      border: 1px solid #d5dde5;
    }
    .btn-cancel {
      background-color: transparent;
      svg {
        margin-top: -2px;
        margin-right: 3px;
      }
    }
    .btn-export-csv {
      background-color: transparent;
      svg {
        margin-top: -2px;
        margin-right: 3px;
      }
    }
    .white-bg {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #00000014;
      border-radius: 4px;
      padding: 1.563rem 1.5rem 4rem 1.5rem;
      margin-bottom: 2.125rem;
      .frm-content {
        @media screen and (min-width: 450px) {
          width: 230px;
        }
      }
      .frm2-content {
        @media screen and (min-width: 450px) {
          width: 80%;
        }
      }
    }
    .frm-btn {
      display: flex;
      justify-content: flex-end;
    }
    .frm-btn2 {
      display: flex;
      justify-content: flex-end;
      @media screen and (min-width: 1024px) {
        display: block;
      }
    }
    .btn-add {
      border: none;
      color: #63ab45;
      font-weight: 600;
      padding: 0px;
    }
    .btn-section {
      display: flex;
      justify-content: space-between !important;
      .back-btn {
        border: 1px solid #202833;
        padding-left: 2.188rem !important;
        padding-right: 2.188rem !important;
      }
    }
  }
}
.set-middle {
  display: table-cell;
  vertical-align: baseline;
}
.btn-redi-padding {
  padding: 8px 18px;
}

#new-spec-step1-form {
  .form-select {
    width: 110%;
  }
  #name {
    width: 110%;
  }
}
.field-v {
  td {
    width: 100%;
    display: block;
    @media screen and (max-width: 1024px) {
      .form-control,
      .form-select {
        font-size: 10px;
      }
    }
  }
  th {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .form-control,
  .form-select {
    font-size: 12px;
    padding: auto 1rem !important;
  }
  #identity {
    width: 70%;
  }
  #format {
    width: 70%;
  }
}
.tbl-field-Validations-and-values {
  @media screen and (max-width: 1440px) {
    width: 1200px;
  }
}

.field-validation {
  // .table > :not(:last-child) > :last-child > * {
  //   border-bottom: 1px solid #d5dde5 ;
  // }
  .border-b {
    border-bottom: 1px solid #d5dde5;
    @media screen and (max-width: 1024px) {
      td {
        font-size: 12px;
      }
    }
  }
}

.empty {
  height: 90vh;
  h1 {
    font-size: 100px;
    font-weight: 600;
  }
  h3 {
    font-weight: 600;
  }
}

.length1 {
  width: 47% !important;
}
.form3 {
  .table-responsive {
    overflow-x: unset;
  }
}

.sidebar-secondary {
  background-color: #e7ebe7;
  position: fixed;
  color: $white;
  height: 100vh;
  width: 205px;
  max-width: 205px;
  margin-left: 70px;
  z-index: 50;

  .sidebar-secondary-item {
    .nav-item {
      a {
        color: #202833;
        text-decoration: none;
        .icon-label-group {
          padding: 20px 40px;
        }
      }
      .bot-border {
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      }
      &:hover {
        background-color: #d5dde5;
        a {
          color: #000000;
        }
      }

      // .sidebar__link.active {
      //   .icon-label-group {
      //     background-color: #d5dde5;
      //   }
      // }
    }
  }
}
.secondary-nav-page {
  margin-left: 270px;
  padding-top: 45px;
}
.title-view-detail {
  h2 {
    font-size: 22px;
  }
}
.b-detail {
  position: relative;
  margin-bottom: 10px;
  .border-btm {
    border-bottom: 1px solid #d5dde5;
    position: absolute;
    width: calc(100% - 110px);
    right: 0;
    top: 50%;
  }
  .border-btm2 {
    border-bottom: 1px solid #d5dde5;
    position: absolute;
    width: calc(100% - 120px);
    right: 0;
    top: 50%;
  }
  h4 {
    color: #707070;
    font-size: 16px;
  }
  .additional-padding {
    padding-right: 10px;
  }
}
.basic-view-detail {
  .top-row {
    font-size: 12px;
    color: #707070;
  }
  .bottom-row {
    font-size: 14px;
    color: #202833;
    margin-bottom: 30px;
  }
  .status-qr-sp {
    text-align: left !important;
  }
}

.is-active-secondry {
  color: green !important;
  font-weight: bold;

  .icon-label-group {
    background-color: #63ab4517;
  }
}

tr.view-field {
  td {
    color: #707070;
  }
}

tr.sub-field {
  td {
    background-color: #f7f7f7 !important;
    .arrow-icon {
      margin-left: 10px;
      margin-right: 10px;
      img {
        margin-top: -20px;
      }
    }
  }
}

.step-3-field {
  background-color: #f7f7f7 !important;
}

.sub-row td {
  background-color: #fffff7 !important;
}
//QR Spec Testing page

.result-warpper {
  margin-top: 20px;

  .result-display-panel {
    margin-top: 10px;
    background-color: #ededed;
    width: 100%;
    padding: 10px;
    min-height: 300px;
  }
}

.payload-hidden-field {
  display: none;
}

.breadcrumb-wrapper {
  display: flex;
  color: #6e6e6e;
  font-size: 12px;

  .first-breadcrumb {
    color: #4b4dcb;
    margin-right: 8px;
  }
}

.header-title-wrapper {
  margin-top: 20px;
  display: flex;

  .show-note-btn {
    margin-left: 20px;
  }

  .review-submit-btn {
    margin-left: 20px;
  }
}

.url-wrapper {
  display: flex;
  justify-content: space-between;

  .label-wrapper {
    width: 85%;
    display: flex;
  }

  .req-type-label {
    background-color: #6fb973;
    color: #e7ebe7;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .req-label {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    border: #b6b6b6 solid 1px;
    width: 100%;
  }
  .send-btn {
    margin-left: 20px;
  }
}

.form-field-wrapper {
  margin-top: 20px;

  .single-field-set {
    margin-top: 20px;
  }
}

.response-wrapper {
  margin-top: 5px;

  .response-code {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    float: right;
    text-align: center;
    height: 40px;
  }
}

.tab-wrapper {
  border-bottom: #b6b6b6 solid 1px;
  border-right: #b6b6b6 solid 1px;
  border-left: #b6b6b6 solid 1px;
  min-height: 200px;
  margin-top: -15px;
}

.payload-header {
  display: flex;

  .side-text {
    font-size: 14px;
    float: right;
    order: 2;
    margin-left: auto;
    margin-top: 5px;
  }
}

.resp-panel {
  max-height: 200px;
  min-height: 200px;
  overflow: auto;
  background-color: rgb(245, 245, 245);
  line-height: 17px;
}

//Review QR Specifications page

.upload-container {
  margin-top: 30px;
  min-height: 150px;

  .upload-field-wrapper {
    padding: 20px;
    text-align: center;
    border-color: rgb(145 145 145) !important;
    min-height: 150px;

    svg {
      font-size: 50px;
      color: #8f8f8f;
      margin-top: 15px;
    }

    p {
      font-weight: 600;
      margin-bottom: 0;
    }

    .hidden-file-input {
      display: none;
    }
  }
}

.doc-table-wrapper {
  margin-top: 30px;
}

//Publish QR Specifications page

.download-all-section {
  border-bottom: #b9b9b9 solid 1px;
  padding-top: 30px;
  padding-bottom: 10px;
}
.test-qr-page {
  font-size: 12px;

  .nav-item {
    .nav-link {
      color: #707070;
      font-size: 12px;
    }

    .nav-link.active {
      color: #272727;
      font-weight: 600;
    }
  }

  .tab-content {
    background-color: #ffff;
    padding: 20px;
    border: 1px solid #dee2e6;
    border-top: 0;

    .payload-header {
      h4 {
        margin-bottom: 0;
      }
    }

    .url-wrapper {
      .req-type-label {
        background-color: #686868;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        line-height: 25px;
      }

      .req-label {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
    .code-editor-wrapper {
      max-height: 300px;
      min-height: 300px;
      max-width: 40vw;
      min-width: 40vw;
      overflow: auto;
      background-color: rgb(245, 245, 245);
      line-height: 17px;
    }
    .w-tc-editor {
      border-radius: 0.25rem;
    }
  }

  .tab-wrapper {
    border: 1px solid #dee2e6;
    margin-top: 10px;
  }

  .accordion {
    .accordion-button {
      &::after {
        display: none;
      }
    }

    .accordion-button:not(.collapsed) {
      color: #000;
      background-color: #ffffff;
    }

    .accordion-body {
      font-size: 14px;
    }
  }

  .responseCodeLabel {
    margin-right: 8px;
  }
}

.doc-icon {
  margin-top: -3px;
  margin-right: 10px;
}

.error-file-msg {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.sidebar-tooltip {
  z-index: 100000;
}
// Action Dropdown component

.action-dropdown-menu {
  margin-top: 8px;
  font-size: 30px;
  margin-right: -10px;

  .dropdown-item-wrapper {
    font-size: 13px;
    cursor: pointer;
  }

  .dropdown-content-wrapper {
    width: 250px;
  }

  .dropdown-right-content-wrapper {
    float: right;
  }

  .stage-badge {
    width: fit-content;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 2px;
    color: #ffffff;
    border-radius: 5px;
    font-size: 10px;
    margin-left: 5px;
  }
}

.noteWrapper {
  margin-bottom: 20px;

  .noteHeader {
    font-size: 20px;
    font-weight: 400;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
    padding-left: 20px;
    border: #dee2e6 solid 1px;
    border-radius: 3px;

    .stage-text {
      margin-left: 10px;
      margin-right: 10px;
    }

    .current-status-text {
      font-size: 15px;
    }
  }
  .noteBody {
    font-size: 14px;
    font-weight: 400;
    border: #dee2e6 solid 1px;

    .singleNoteWrapper {
      padding-top: 20px;

      .info {
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 20px;
        border-bottom: #dee2e6 solid 1px;

        .date-wrapper {
          float: right;
          margin-bottom: 10px;
        }
      }

      .file-wrapper {
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    .noData {
      margin-top: 10px;
      color: #e3b748;
    }
  }
}
.page-demo {
  .stage-text {
    display: none;
  }
  .date-wrapper {
    font-size: 16px;
    font-weight: 300;
  }
  .review-table {
    @extend %white-bg-shared;
    padding: 1.563rem 1.5rem 1.5rem 1.5rem !important;
    strong {
      font-weight: 500;
    }
    button {
      font-weight: 600;
    }
    .noteHeader {
      border: 0px;
      border-bottom: #dee2e6 solid 1px;
    }
    .noteBody {
      border: 0px;
      .table > :not(caption) > * > * {
        border: 0px;
      }
      .download-all-section {
        border: 0px;
        margin-bottom: 0px;
        h4 {
          color: #000;
          font-size: 1.125rem;
        }
        .b-detail {
          margin-bottom: 0px;
        }
      }
      .table {
        thead tr th,
        tbody tr {
          border-bottom: 1px solid #d5dde5;
        }
      }
      .singleNoteWrapper {
        .info {
          border: 0px;
        }
      }
    }
  }
}
%white-bg-shared {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000014;
  border-radius: 4px;
  padding: 1.563rem 1.5rem 4rem 1.5rem;
  margin-bottom: 2.125rem;
  .frm-content {
    @media screen and (min-width: 450px) {
      width: 230px;
    }
  }
  .frm2-content {
    @media screen and (min-width: 450px) {
      width: 80%;
    }
  }
}

.qrSpec-details-wrapper {
  margin-left: 20px;
  margin-top: 20px;
}

.history-list {
  margin-left: 2%;

  .infinit-scroller{
    display: flex;
    flex-direction: column-reverse;
  }

  .no-history-data {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #e3b748;
    text-align: center;
    
  }

  .history-item {
    margin-bottom: 15px;

    .history-item-header{
      display: flex;

      .content-wrapper {
        display: flex;
        align-items: center;
        padding-left: 20px;
  
        .name {
          font-size: 14px;
          font-weight: 600;
          color: #000;
        }
  
        .description {
          margin-left: 5px;
          font-size: 14px;
          color: #000;
        }
  
        .date {
          margin-left: 5px;
          font-size: 14px;
          font-weight: 600;
          color: #88898a;
        }
      }
    }
    .history-tags-wrapper {
      display: flex;
      align-items: center;
      margin-left: 80px;
      margin-bottom: 5px;
      font-size: 14px;
    }

    .history-details-wrapper{
      display: flex;
      align-items: center;
      margin-left: 80px;
      margin-bottom: 5px;
      flex-wrap: wrap;

      .field{
        font-size: 14px;
        color: #8993A4;
        font-weight: 600;
        margin-right: 10px;
        margin-left: 20px;
      }
      .break {
        flex-basis: 100%;
        height: 0;
      }
      .field-description {
        font-size: 14px;
        margin-bottom: 10px;
        margin-top: 15px;
        font-weight: 400;
        margin-right: 10px;
      }

      .fromValue{
        padding-left: 4px;
        padding-right: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        background-color: #DFE1E6;
        color: #42526E;
        display: inline-block;
        box-sizing: border-box;
        border-radius: 3px;
        font-size: 11px;
        font-weight: 700;
        line-height: 1;
        max-width: 500px;
        word-wrap: break-word;
      }

      .toValue{
        padding-left: 4px;
        padding-right: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        margin-left: 5px;
        background-color: #DFE1E6;
        color: #42526E;
        display: inline-block;
        box-sizing: border-box;
        border-radius: 3px;
        font-size: 11px;
        font-weight: 700;
        line-height: 1;
        max-width: 500px;
        word-wrap: break-word;
      }
    }
  }
}

.invalid-err-msg{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.multi-select-dropdown {
  display: block !important;
  width: 31.6vw;
}

.trx-type-tooltip{
  margin-left: 5px;
}

.trx-type-value{
  margin-right: 4px;
}


.additional-fields-container {
  height: 300px;
  overflow-y: scroll;
}

.template-cpi-container {
  min-height: 296px;
}

.color-field-center {
  display: flex;
  align-items: center; 
  gap: 10px; 
}